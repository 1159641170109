<template lang="">
  <footer id="footer">
    <div class="w_1180 f_height">
        <div id="f_logo">
            <a href="https://www.cyxn.com/"><img src="@/assets/images/f_img2.png"></a>
            <span></span>
        </div>

        <!--服务目录-->
        <dl v-for="(item,index) in categoryList" :key="index">
            <dt>
                <router-link :to="{path:'/service',query:{category_id:item.main_category_id}}">{{item.main_category_title}}

                </router-link></dt>
            <dd v-for="(item2,index2) in item.content">
                <router-link :to="{path:'/more',query:{id:item2.second_id}}">{{item2.second_title}}</router-link>
            </dd> 
        </dl>
        <dl>
            <dt><a href="javascript:void(0)">关注创业小鸟</a></dt>
            <div class="sideMenu2" style="margin:0 auto">
                <h3 @mouseover="toggleDisplay('menu1')" >创业小鸟公众号</h3>
                <ul v-show="displayMenu === 'menu1'">
                    <li><img style="width: 120px;height: 120px;" :src="footerList.weibo_qr_code"></li>
                </ul>
                <h3 @mouseover="toggleDisplay('menu2')"  class="on">创业小鸟小程序</h3>
                <ul v-show="displayMenu === 'menu2'">
                    <li><img style="width: 120px;height: 120px;" :src="footerList.mp_program_qr_code"></li>
                </ul>
                <h3 @mouseover="toggleDisplay('menu3')" >创业小鸟微博</h3>
                <ul v-show="displayMenu === 'menu3'">
                    <li><img style="width: 120px;height: 120px;" :src="footerList.weibo_qr_code"></li>
                </ul>
            </div>
        </dl>
    </div>
    <div class="w_1180 clearfix footext1">
        <!--热门服务-->
        <div class="ul1">
            <span>热门服务</span>
            <ul>
                <li v-for="(item,index) in hotService" :key="index"><router-link :to="{path:'/detail',query:{category_id:item.main_category_id,product_id:item.id}}">{{item.title}} </router-link></li>
            </ul>
        </div>

        <!--常用工具-->
        <div class="ul1">
            <span>常用工具</span>
            <ul>
                <li v-for="(item,index) in toolsList" :key="index"><a :href="item.link">{{item.title}}</a></li>
            </ul>
        </div>

        <!--友情链接-->
        <div class="ul1">
            <span>友情链接</span>
            <ul>
                <li v-for="(item,index) in linkList" :key="index"><a :href="item.link">{{item.name}}</a></li>
            </ul>
        </div>
    </div>
    <div class="w_1180 footext2">
        <router-link to="/about">关于我们</router-link>
        <router-link to="/certificate">公司资质</router-link>
        <router-link to="/join">加入小鸟</router-link>
        <router-link to="/contact">联系我们</router-link>
        <router-link to="/pay">支付方式</router-link>
        <!--        <a href="/help/index.html">帮助中心</a>-->
        <router-link to="/agreement">服务协议</router-link>
        <router-link to="/privacy">隐私政策</router-link>
        <img src="@/assets/images/ico11.png">
        <span>{{footerList.contact_phone}}</span>
    </div>
    <div class="w_1180 footext3">
        <p>©2015-2024 cyxn.com 版权所有</p>
        <p><a :href="footerList.icp_query_link" target="_blank">{{footerList.icp_number}}</a>
            <a :href="footerList.network_security_query_link"
                target="_blank">
                <img src="@/assets/images/ico12.png"> {{footerList.network_security_number}}</a>
            <a :href="footerList.tipo_query_link" target="_blank">
                <img src="@/assets/images/ico13.png"> 国家知识产权局备案代理机构</a>
            <a :href="footerList.enterprise_credit_query_link"
                target="_blank">
                <img src="@/assets/images/ico14.png"> 国家企业信用信息公示系统（广东）</a>
        </p>
    </div>
</footer>
</template>
<script>
export default {
  data() {
    return {
      name:'',
      footerList:[],
      linkList:[],
      toolsList:[],
      hotService:[],
      categoryList:[],
      displayMenu:'menu2'
    }
  },
  metaInfo() {
      return {
        meta: [
          {
            name: 'keywords',
            content:  this.footerList ? this.footerList.seo_keywords : ' ',
          },
          {
            name: 'description',
            content: this.footerList ? this.footerList.seo_description : ' ',
          },
        ],
      }
    },
  mounted() {
    this.getFooter()
    this.getService()
    this.getCategory()
    this.getTools()
    this.getLink()
  },
  watch: {
    footerList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            this.updateMetaInfo();
          });
        }
      },
      immediate: true, 
    },
  },
  methods: {
    updateMetaInfo() {
      this.$metaInfo.meta = [
        {
          name: 'keywords',
          content: this.footerList.seo_keywords,
        },
        {
          name: 'description',
          content: this.footerList.seo_description,
        },
      ];
    },
    async getService() {
                let res = await this.$request.post('servicecategory/getPic')
                if (res.data.code === 0) {
                    const ServiceList = res.data.data
                    this.hotService = ServiceList.filter(item => item.is_hot === '是')
                
                }
            },
    async getTools(){
        const res =await this.$request.post('tools/getAll')
      if (res.data.code===0) {
        this.toolsList=res.data.data
      }
    },
    async getLink(){
      const res =await this.$request.post('link/getAll')
      if (res.data.code===0) {
        this.linkList=res.data.data
      }
    },
    async getFooter(){
      const res =await this.$request.post('seo/getPaginate')
      if (res.data.code===0) {
        this.footerList=res.data.data.data[0]
      }
    },
    async getCategory(){
        let res = await this.$request.post('servicemaincategory/getCategory')
        if (res.data.code===0) {
        this.categoryList=res.data.data
        this.categoryList=this.changeApply(this.categoryList)
      }
    },
    changeApply(arr) {
                // 使用reduce函数来分组
                const groupedByDomain = arr.reduce((groups, item) => {
                    // 如果该domain（分类ID）的组不存在，则创建一个新组
                    if (!groups[item.id]) {
                        groups[item.id] = {
                            main_category_title: item.title, // 分类名称
                            main_category_id: item.id, // 分类id
                            content: [] // 初始化内容数组
                        };
                    }
                    // 将当前项添加到对应domain（分类ID）的组中
                    groups[item.id]?.content.push(item);
                    return groups;
                }, {});

                // 将分组后的对象数组化
                const result = Object.values(groupedByDomain);
                return result
            },
     toggleDisplay(menuId) {
          this.displayMenu = menuId;
         }
  },
}
</script>
<style lang="css">
   .copyright a:last-child img{
    width: 100%;
   }
</style>