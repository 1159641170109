<template>
  <div  id="app">
        <Top/>
        <div id="total_content">
        <router-view/>
      </div>
      <Footer/>
  </div>
</template>

<script>
  import Top from '@/components/top'
  import Footer from '@/components/footer'
  export default {
  components:{
    Top,
    Footer,
  },
  computed:{
  },
}
</script>

<style>
 .image_body{
    background:url(@/assets/images/index_top_bg.jpg) repeat-x;
 }
</style>
