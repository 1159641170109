<template>
  <el-dialog
    title="免费预约看场"
    :visible.sync="dialogVisible"
    width="780px"
    :style="{height:dialogHeight}"
    >
    <div style="display: flex; justify-content:space-around;">
      <div class="l"><el-form :model="consultationForm" label-width="100px" :rules="rules" ref="consultationForm">
       
        <el-form-item style="margin-bottom: 0;" label="联系电话" prop="contact_phone">
          <el-input v-model="consultationForm.contact_phone" placeholder="请输入您的手机号，方便获取咨询结果"></el-input>
          <div class="appdia" style="display: flex; align-items: center;">
            <img class="safety" src="@/assets/images/tc.png" alt="">
            <span class="safety2">信息保护中，请放心填写</span>
          </div>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="consultationForm.contact_name" placeholder="请输入联系人姓名，如王先生/张小姐"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="consultationForm.agreeProtocol" style="margin-left: 10px;">我已阅读并同意<a href="/agreement" target="_blank">《创业小鸟网络服务使用协议》</a><a href="/privacy" target="_blank">《隐私保护政策》</a></el-checkbox>
        </el-form-item>
      </el-form> 
      <span slot="footer" class="dialog-footer">
        <el-button style="position: absolute; left: 50%;" type="primary" @click="submitConsultation">免费预约看场</el-button>
      </span></div>
      <div class="r">
        <div class="text">
          <dl class="dl1">
              <dt>专业服务 品质保证</dt>
              <dd>★不成功，全额退款</dd>
              <dd>★一个工作日内申报</dd>
              <dd>★风险倒逼服务提升</dd>
              <dd>★注册成功率更用保障</dd>
          </dl>
      </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogHeight: '500px',
      consultationForm: {
        contact_phone: '',
        contact_name: '',
        agreeProtocol:false
      },
      rules: {
        contact_phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async submitConsultation() {
      if (this.consultationForm.agreeProtocol) {
        this.$refs.consultationForm.validate(async valid => {
        if (valid) {
          let res = await this.$request.post('clusterreserve/add',this.consultationForm)
          if (res.data.code===0) {
            this.$message.success("提交成功")
          }
          // 发送请求...
          this.dialogVisible = false;
        } else {
          alert('提交失败，联系电话不能为空');
          return false;
        }
      });
      }else{
        alert('请先勾选协议')
      }
    }
  }
};
</script>

<style lang="css" scoped>
  .l{
    position: relative;
  }
  ::v-deep .el-dialog{
    height: 350px;
  }
 ::v-deep .el-input__inner{
  width: 420px !important;
 }
 .el-button--primary{
  background: #F58634 !important;
  border-color: #f58634 !important;
 }
 ::v-deep .safety2{
  color: #1ad2a0;
 }
 .r .text .dl1{
 }
 ::v-deep .r .text .dl1 dt{
  line-height: 52px;
  font-size: 14px;
 }
 ::v-deep .r .text .dl1 dd{
  line-height: 36px;
  color: #8a8a8a;
 }
</style>